$(function () {

    var filters_wrapper = document.getElementById('filter_sticky'),
    filter_name, sticky_elm, page;

    if (filters_wrapper) {

        filter_name = $('.filter_wrapper.collapsible').children('.filter_name');
        if (typeof(filters_wrapper.elements)!='undefined') {
            page = parseFloat(filters_wrapper.elements.page.value);
            var nb_prod = parseFloat(filters_wrapper.elements.nb_prod.value);
        } else {
            var nb_prod = 0;
        }

        function onFilterNameClick() {

            var wrapper = this.parentElement,
            filters = this.nextElementSibling,
            action = (wrapper.classList.toggle('collapsed')) ? 'slideUp' : 'slideDown';

            $(filters).stop(true, true)[action]();
        }

        filter_name.on('click', onFilterNameClick);
    }

    if ($('.pager_wrapper').length > 0) {

        window.addEventListener('DOMContentLoaded', paginate.bind(this, ((page/nb_prod)+1)));
    }
}());
