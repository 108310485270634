(function () {

	"use strict";

	// Capitalizes first word of a sentence
	function ucfirst() {

		return this[0].toUpperCase() + this.substr(1);
	}

	// Gets value from a form control
	function getValue(ctrl, func) {

		var output = undefined;

		if (this.elements[ctrl]) {

			output = this.elements[ctrl].value.trim();

			if (func && func.constructor === Function) {

				output = func(output);
			}
		}

		return output;
	}

	// Polyfill for Array.prototype.includes
	function includes(val) {

		return (this.indexOf(val) !== -1);
	}

	// Generates a random float between min and max
	function randomDec(min, max) {

 	   return Math.random() * (max - min) + min;
	}

	// Generates a random integer between min and max
	function randomInt(min, max) {

 	   return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	// Generates a random number between min and max
	// Set int to true to generate integers instead of floats
	function randRange(min, max, int) {

		return (int) ? randomDec(min, max) : randomInt(min, max);
	}

	if (!String.prototype.ucfirst) {

		Object.defineProperty(String.prototype, 'ucfirst', { value : ucfirst });
	}

	if (!HTMLFormElement.prototype.getValue) {

		Object.defineProperty(HTMLFormElement.prototype, 'getValue', { value : getValue });
	}

	if (!HTMLFieldSetElement.prototype.getValue) {

		Object.defineProperty(HTMLFieldSetElement.prototype, 'getValue', { value : getValue });
	}

	if (!Array.prototype.includes) {

		Object.defineProperty(Array.prototype, 'includes', { value : includes });
	}

	if (!Math.randRange) {

		Object.defineProperty(Math, 'randRange', { value : randRange });
	}
}());
